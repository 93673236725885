@import 'colors';

html {
  height: 100%;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
}

body {
  background-color: $color-black;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  height: 100%;
}

.noscript,
.mobile {
  margin: 0 auto;
  padding-top: 200px;
  width: 100%;
  text-align: center;
  color: $color-white;
}

#root {
  height: 100%;
}
