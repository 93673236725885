@import 'src/scss/colors';

$power-on-background: rgba($color-white, .7);

.button {
  &-power {
    background-color: $power-on-background;
    border: 1px solid $power-on-background;
    border-radius: 50%;

    &__on {
      width: 64px;
      animation: pulse 2s infinite;

      @keyframes pulse {
        0% {
          box-shadow: 0 0 0 0 rgba($power-on-background, .4);
        }
        70% {
          box-shadow: 0 0 0 30px rgba($color-black, 0);
        }
        100% {
          box-shadow: 0 0 0 0 rgba($color-black, 0);
        }
      }
    }

    &__off {
      position: fixed;
      width: 24px;
      top: 20px;
      right: 20px;
      opacity: .5;
    }

    &:hover {
      background-color: $color-white;
      border: 1px solid $color-white;
      cursor: pointer;
    }
  }
}
