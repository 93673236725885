.app {
  height: 100%;
  overflow: hidden;

  &--off {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fadein 2s;
  }

  @keyframes fadein {
    from { opacity: 0; }
    to { opacity: 1; }
  }
}
