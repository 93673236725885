// whites
$color-white: #fff;
$color-mystic: #e0e8f0;

// blacks
$color-black: #000;
$color-onyx: #121010;

// grays
$color-steel-gray: #232334;
$color-ebony: #30353a;
$color-dark-gray: #575658;

// blues
$color-blue: #0000ff;
$color-medium-electric-blue: #0a5096;
$color-daintree: #022833;

// reds
$color-red: #ff0000;
$color-carnation: #fc605c;

// yellows
$color-pastel-orange: #fdbc40;

// greens
$color-green: #00ff00;
$color-dull-lime: #34c749;
