@import 'src/scss/colors';

.window {
  background: $color-daintree;
  border: solid .5px rgba($color-black, 0.1);
  border-radius: 8px 8px;
  min-width: 900px;
  max-width: 900px;
  width: 100%;
  position: absolute;
  top: 0;
  opacity: 1;

  &[disabled] {
    opacity: 0.8;
  }

  &-bar {
    background: $color-mystic;
    height: 30px;
    border-radius: 8px 8px 0 0;
    padding-left: 10px;
    text-align: left;

    .button {
      border: rgba($color-black, .2) .5px solid;
      height: 14px;
      width: 14px;
      border-radius: 50%;
      display: inline-block;
      margin: 8px;
      cursor: pointer;

      &--red {
        background-color: $color-carnation;
        position: relative;

        &.hover {
          &:before,
          &:after {
            content: '';
            position: absolute;
            width: 9px;
            height: 1px;
            background-color: rgba($color-black, .7);
            top: 7px;
            box-shadow: 0 0 2px 0 #ccc;
          }

          &:before {
            transform: rotate(45deg);
            left: 2.5px;
          }

          &:after {
            transform: rotate(-45deg);
            right: 2.5px;
          }
        }
      }

      &--yellow {
        background-color: $color-pastel-orange;

        &.hover {

        }
      }

      &--green {
        background-color: $color-dull-lime;

        &.hover {

        }
      }
    }
  }
}
